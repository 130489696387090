import React from "react";


const SearchCardLeft = ({ groupData }) => {

    return (
        <div className="">
            <div className="group flex items-center font-bold text-blue-600 hover:text-blue-950 hover:tracking-wide transition-all relative">
                <span className='mr-2'>
                    <a href="#">{groupData.company}</a>
                </span>
                <div className="hidden group-hover:flex relative transition-all right-0">
                    <a href={groupData.website} target="_blank" rel="noopener noreferrer" className="mx-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 hover:stroke-blue-500 transition-colors">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                        </svg>
                    </a>
                    <a href={groupData.crunchbase} target="_blank" rel="noopener noreferrer" className="mx-1" >
                    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth={0} stroke="currentColor" className="w-3 h-3 hover:fill-blue-500 transition-colors">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.6 0H2.4A2.41 2.41 0 0 0 0 2.4v19.2A2.41 2.41 0 0 0 2.4 24h19.2a2.41 2.41 0 0 0 2.4-2.4V2.4A2.41 2.41 0 0 0 21.6 0zM7.045 14.465A2.11 2.11 0 0 0 9.84 13.42h1.66a3.69 3.69 0 1 1 0-1.75H9.84a2.11 2.11 0 1 0-2.795 2.795zm11.345.845a3.55 3.55 0 0 1-1.06.63 3.68 3.68 0 0 1-3.39-.38v.38h-1.51V5.37h1.5v4.11a3.74 3.74 0 0 1 1.8-.63H16a3.67 3.67 0 0 1 2.39 6.46zm-.223-2.766a2.104 2.104 0 1 1-4.207 0 2.104 2.104 0 0 1 4.207 0z"/>
                    </svg>
                    </a>
                    <a href={groupData.linkedin} target="_blank" rel="noopener noreferrer" className="mx-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth={0} stroke="currentColor" className="w-3 h-3 hover:fill-blue-500 transition-colors">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                    </a>
                </div>
            </div>


            <div className="mb-0 text-xs font-light text-indigo-400">
                {groupData.type_of_company} | {groupData.employees} | $ {groupData.funding} | {groupData.funding_category}
            </div>
        </div>

    );
};

export default SearchCardLeft;