import React, { createContext, useContext, useEffect, useState } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize WebSocket connection
    const ws_protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const ws_host = '127.0.0.1:8001';
    const socketUrl = ws_protocol + ws_host + '/ws/sales/';
    const newSocket = new WebSocket(socketUrl);
    setSocket(newSocket);

    // Cleanup on unmount
    return () => newSocket.close();
  }, []);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
