import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


const LinkTag = ({ to, children }) => {
    const location = useLocation();
    const isactive = location.pathname === to;

    return (
        <Link
            to={to}
            className={`flex items-center py-2.5 pl-2.5 mt-2 rounded-full transition duration-200 ${isactive
                    ? "bg-indigo-800 text-white"
                    : "hover:bg-indigo-800 hover:text-white"
            }`}>
            
            {React.Children.map(children, child => 
                React.isValidElement(child) ? React.cloneElement(child, { isactive }) : child
            )}
        </Link >
    );

};

// Custom LogoutButton component for the logout action
const LogoutButton = ({ onClick,isSidebarOpen }) => {
    return (
      <button
        onClick={onClick}
        className="flex items-center py-2.5 px-2.5 mt-2 rounded-full transition duration-200 hover:bg-indigo-800 hover:text-white w-full">
        <svg className={`h-5 w-5 ${isSidebarOpen ? 'mr-2' : ''}`} fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
        
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />


        </svg>
        {isSidebarOpen ? 'Logout' : ''}
      </button>
    );
  };

const Sidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const navigate = useNavigate();

    const { logout } = useAuth(); 
    const handleLogout = () => {
        logout(() => navigate('/login')); // Pass navigation as callback to logout
    };
      

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>

<div
      id="sidebar"
      className={`bg-gradient-to-tl from-indigo-700 to-indigo-600 text-white ${
        isSidebarOpen ? 'w-64' : 'w-14'
      } space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } md:relative md:translate-x-0 transition-width duration-200 ease-in-out lg:translate-x-0 z-50 flex flex-col`}>
      

                <div className={`flex items-center rounded transition duration-200 hover:bg-indigo-800 hover:text-white ${isSidebarOpen ? 'justify-between py-2.5 px-4' : 'justify-end py-2.5 px-3'}`}>

                    {isSidebarOpen ?
                        <Link to="/" className="text-white flex items-center space-x-2">
                            <span className="text-2xl font-bold">Dashboard</span>
                        </Link>
                        :
                        <span></span>
                    }

                    <button onClick={toggleSidebar} className="toggle-button">
                        {isSidebarOpen ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 pl-2.5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                            </svg>
                        }
                    </button>
                </div>
                <nav>
                    <LinkTag to="/" >
                        <div className='flex items-center'>
                            <svg className={`h-5 w-5 ${isSidebarOpen ? 'mr-2' : ''}`} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"></path>
                            </svg>
                            {isSidebarOpen ? 'Home' : ''}
                        </div>

                    </LinkTag>
                    <LinkTag to="/reports">

                        <svg className={`h-5 w-5 ${isSidebarOpen ? 'mr-2' : ''}`} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>

                        </svg>
                        {isSidebarOpen ? 'Reports' : ''}

                    </LinkTag>

                    <LinkTag to="/search">

                        <svg className={`h-5 w-5 ${isSidebarOpen ? 'mr-2' : ''}`} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>




                        {isSidebarOpen ? 'Search' : ''}

                    </LinkTag>
                    <LinkTag to="/checks">
                        
                        <svg className={`h-5 w-5 ${isSidebarOpen ? 'mr-2' : ''}`} fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
                            <path fillRule="evenodd" d="M11.622 1.602a.75.75 0 01.756 0l2.25 1.313a.75.75 0 01-.756 1.295L12 3.118 10.128 4.21a.75.75 0 11-.756-1.295l2.25-1.313zM5.898 5.81a.75.75 0 01-.27 1.025l-1.14.665 1.14.665a.75.75 0 11-.756 1.295L3.75 8.806v.944a.75.75 0 01-1.5 0V7.5a.75.75 0 01.372-.648l2.25-1.312a.75.75 0 011.026.27zm12.204 0a.75.75 0 011.026-.27l2.25 1.312a.75.75 0 01.372.648v2.25a.75.75 0 01-1.5 0v-.944l-1.122.654a.75.75 0 11-.756-1.295l1.14-.665-1.14-.665a.75.75 0 01-.27-1.025zm-9 5.25a.75.75 0 011.026-.27L12 11.882l1.872-1.092a.75.75 0 11.756 1.295l-1.878 1.096V15a.75.75 0 01-1.5 0v-1.82l-1.878-1.095a.75.75 0 01-.27-1.025zM3 13.5a.75.75 0 01.75.75v1.82l1.878 1.095a.75.75 0 11-.756 1.295l-2.25-1.312a.75.75 0 01-.372-.648v-2.25A.75.75 0 013 13.5zm18 0a.75.75 0 01.75.75v2.25a.75.75 0 01-.372.648l-2.25 1.312a.75.75 0 11-.756-1.295l1.878-1.096V14.25a.75.75 0 01.75-.75zm-9 5.25a.75.75 0 01.75.75v.944l1.122-.654a.75.75 0 11.756 1.295l-2.25 1.313a.75.75 0 01-.756 0l-2.25-1.313a.75.75 0 11.756-1.295l1.122.654V19.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
                            </svg>




                        {isSidebarOpen ? 'Checks' : ''}

                    </LinkTag>
                    <LinkTag to="/weekly_checks">
                        
                        <svg className={`h-5 w-5 ${isSidebarOpen ? 'mr-2' : ''}`} fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
                            <path fillRule="evenodd" d="M11.622 1.602a.75.75 0 01.756 0l2.25 1.313a.75.75 0 01-.756 1.295L12 3.118 10.128 4.21a.75.75 0 11-.756-1.295l2.25-1.313zM5.898 5.81a.75.75 0 01-.27 1.025l-1.14.665 1.14.665a.75.75 0 11-.756 1.295L3.75 8.806v.944a.75.75 0 01-1.5 0V7.5a.75.75 0 01.372-.648l2.25-1.312a.75.75 0 011.026.27zm12.204 0a.75.75 0 011.026-.27l2.25 1.312a.75.75 0 01.372.648v2.25a.75.75 0 01-1.5 0v-.944l-1.122.654a.75.75 0 11-.756-1.295l1.14-.665-1.14-.665a.75.75 0 01-.27-1.025zm-9 5.25a.75.75 0 011.026-.27L12 11.882l1.872-1.092a.75.75 0 11.756 1.295l-1.878 1.096V15a.75.75 0 01-1.5 0v-1.82l-1.878-1.095a.75.75 0 01-.27-1.025zM3 13.5a.75.75 0 01.75.75v1.82l1.878 1.095a.75.75 0 11-.756 1.295l-2.25-1.312a.75.75 0 01-.372-.648v-2.25A.75.75 0 013 13.5zm18 0a.75.75 0 01.75.75v2.25a.75.75 0 01-.372.648l-2.25 1.312a.75.75 0 11-.756-1.295l1.878-1.096V14.25a.75.75 0 01.75-.75zm-9 5.25a.75.75 0 01.75.75v.944l1.122-.654a.75.75 0 11.756 1.295l-2.25 1.313a.75.75 0 01-.756 0l-2.25-1.313a.75.75 0 11.756-1.295l1.122.654V19.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
                            </svg>




                        {isSidebarOpen ? 'Weekly Checks' : ''}

                    </LinkTag>


              
                    <div className="logout-container" style={{ marginTop: 'auto' }}>
                        <LogoutButton onClick={handleLogout} isSidebarOpen={isSidebarOpen} />
                    </div>




                </nav>

            </div>
        </>

    );
};

export default Sidebar;
