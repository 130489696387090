import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate(); // Initialize useNavigate

  // Update credentials state on input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prevCredentials => ({
      ...prevCredentials,
      [name]: value
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Here, replace 'YOUR_DJANGO_LOGIN_ENDPOINT' with your actual login endpoint
      const response = await axios.post('http://localhost:8001/login_app/login/', credentials);
      localStorage.setItem('token', response.data.token); // Save the received token
      
      // Redirect to the homepage or another protected route
      navigate('/'); // Redirects to the homepage after successful login
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login failure (e.g., displaying an error message)
    }
  };

  return (
    // <div class="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md mx-auto">
        <form onSubmit={handleLogin} className="bg-white shadow-lg rounded-lg p-8">
          <div className="mb-4">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={credentials.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={credentials.password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Login
          </button>
        </form>
      </div>
    //{/* </div> */}




  );
};

export default Login;
