import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Reports = () => {
    const [files, setFiles] = useState([]);
    
        useEffect(() => {
            axios.get('http://127.0.0.1:8001/reports') // Make sure the port is correct
                .then(response => {
                    setFiles(response.data.files); // Update according to your JSON structure
                })
                .catch(error => {
                    console.error('There was an error fetching the reports', error);
                });
        }, []);

    return (
        <div className="container mx-auto mt-4 p-5 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-semibold mb-6 text-gray-800">Reports</h1>
            <table className="min-w-full leading-normal">
                <thead>
                    <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Report Name
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Last Modified (Date | Time)
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100"></th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => (
                        <tr key={file.name}>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                                {file.name}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                                {file.last_modified}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <a href={`http://127.0.0.1:8001/download-file/${encodeURIComponent(file.name)}`} className="inline-block bg-indigo-600 hover:bg-indigo-500 text-white font-light text-xs py-1 px-4 rounded transition duration-300">Download</a>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Reports;
