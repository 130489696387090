// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-dotted {
    background-image: linear-gradient(to bottom, rgb(92, 9, 227) 50%, rgba(226, 3, 255, 0) 50%);
    background-size: 2px 10px;
}

.bg-solid {
    background-color: rgb(92, 9, 227);
}
`, "",{"version":3,"sources":["webpack://./src/styles/tables.css"],"names":[],"mappings":"AAAA;IACI,2FAA2F;IAC3F,yBAAyB;AAC7B;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".bg-dotted {\n    background-image: linear-gradient(to bottom, rgb(92, 9, 227) 50%, rgba(226, 3, 255, 0) 50%);\n    background-size: 2px 10px;\n}\n\n.bg-solid {\n    background-color: rgb(92, 9, 227);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
