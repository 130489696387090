import React, { useState } from 'react';
import TableView from './SearchComponents/TableView/TableView';
import FullCard from './SearchComponents/FullSearchCard';

const PreLoader = () => {

    return (
        <div class="flex justify-center items-center">
            <div class="animate-spin rounded-full h-6 w-6 ml-10 border-b-2 border-gray-300"></div>
        </div>

    );
};

const SearchComponent = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const handleSearch = async () => {
        setIsLoading(true);
        const searchTerms = searchQuery ? searchQuery.split(';') : [];

        let allResults = [];

        for (const term of searchTerms) {
            try {
                const response = await fetch(`http://127.0.0.1:8001/search_app/search/?q=${term}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); // Convert response to JSON
                console.log("Received data:", data); // Log the received data
                console.log("Data type:", typeof data); // Log the type of the received data

                // If 'data.results' exists and is an array, concatenate its items to 'allResults'
                if (data.results && Array.isArray(data.results)) {
                    allResults = [...allResults, ...data.results];
                } else {
                    console.warn("Unexpected data structure:", data);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        }

        setResults(allResults);
        setIsLoading(false);
    };


    const groupByLeadEmail = (data) => {
        return data.reduce((group, item) => {
            const {
                lead_email,
                lead_name,
                lead_company_name,
                lead_custom_fields_title,
                lead_website,
                cb_link,
                employees,
                linkedin_url,
                company_linkedin_url,
                contact_source,
                sent_details,  // Array of sent details
                open_times,    // Array of open times
                reply_times    // Array of reply times
            } = item;
    
            if (!group[lead_email]) {
                group[lead_email] = {
                    items: [], // May not be needed depending on your data structure
                    name: lead_name,
                    company: lead_company_name,
                    website: lead_website,
                    title: lead_custom_fields_title,
                    crunchbase: cb_link,
                    employees: employees,
                    linkedin_url: linkedin_url,
                    company_linkedin_url: company_linkedin_url,
                    contact_source: contact_source,
                    sentDetails: [],  // Initialize the array
                    openTimes: [],    // Initialize the array
                    replyTimes: []    // Initialize the array
                };
            }
    
            // Spread operator to push all sent_details into the sentDetails array
            group[lead_email].sentDetails.push(...sent_details.map(detail => ({
                time: detail.sent_time,
                sequence: detail.sequence_number,
            })));
    
            // Spread operator to push all open_times into the openTimes array
            group[lead_email].openTimes.push(...open_times);
    
            // Spread operator to push all reply_times into the replyTimes array, filtering out 'None'
            group[lead_email].replyTimes.push(...reply_times.filter(time => time !== 'None'));
    
            return group;
        }, {});
    };
    


    const groupedResults = groupByLeadEmail(results);

    return (
        <div className="m-6 p-4">
            {/* Search Bar */}
            <div className="relative flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="grey" className="w-6 h-6 absolute left-3">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
                <input
                    type="text"
                    className="border py-2 pl-14 w-96 rounded-full focus:border-cyan-500 transition-colors"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
                {isLoading && <PreLoader />}
                
            </div>


            <TableView groupedResults={groupedResults} />
            <FullCard groupedResults={groupedResults} />


        </div>
    );
};

export default SearchComponent;
