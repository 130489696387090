import React, { useEffect, useState } from 'react';
import {useWebSocket} from './WebSocketProvider'
import StatusCard from './DashboardComponents/Card';


const Dashboard = () => {

    const [isChecked, setIsChecked] = useState(true); // Initial state for checkboxes
    const [numberOfWeeks, setNumberOfWeeks] = useState(2); // State for number of weeks
    const socket = useWebSocket();; // State for WebSocket
    const [message, setMessage] = useState('...'); // State for WebSocket messages
    const [progress, setProgress] = useState(0); // State for progress
    const [update, setUpdate] = useState('waiting'); // State for progress
    const [stageDone, setStageDone] = useState([false, false, false, false, false])

    const [checkboxStates, setCheckboxStates] = useState({
        'pull-smartlead-data': true,
        'update-sales-dashboard': true,
        'create-post-sequence-report': true,
        'create-linkedin-report': true,
        'create-intro-calls-weekly-report': true,
    });

    const cardData = [
        {
            checkboxId: 'pull-smartlead-data',
            label: 'Pull Smartlead Data',
            isCheckedKey: 'pull-smartlead-data',
            statusIndex: 0
        },
        {
            checkboxId: 'update-sales-dashboard',
            label: 'Update Sales Dashboard',
            isCheckedKey: 'update-sales-dashboard',
            statusIndex: 1
        },
        {
            checkboxId: 'create-post-sequence-report',
            label: 'Create Post Sequence Report',
            isCheckedKey: 'create-post-sequence-report',
            statusIndex: 2
        },
        {
            checkboxId: 'create-linkedin-report',
            label: 'Create LinkedIn Report',
            isCheckedKey: 'create-linkedin-report',
            statusIndex: 3
        },
        {
            checkboxId: 'create-intro-calls-weekly-report',
            label: 'Create Intro Calls Weekly Report',
            isCheckedKey: 'create-intro-calls-weekly-report',
            statusIndex: 4
        },
        // Add more objects for each card you need
    ];
    
    // Function to toggle the checkbox state
    const toggleCheckbox = (id) => {
        setCheckboxStates(prevStates => ({
            ...prevStates,
            [id]: !prevStates[id]
        }));
    };

    // WebSocket initialization
    useEffect(() => {
        
        if (socket) {
            socket.onmessage = function(e) {
                const data = JSON.parse(e.data);

                console.log(data)
                setMessage(data.message);
                setProgress(data.progress);
                setUpdate(data.update);

                // Update only the specific stage
                if (data.hasOwnProperty('stageIndex') && data.hasOwnProperty('stageStatus')) {
                    setStageDone(prevStages => {
                        const updatedStages = [...prevStages];
                        updatedStages[data.stageIndex] = data.stageStatus;
                        return updatedStages;
                    });
                }
                
                // Update UI based on received data
            };
        }

        }, [socket]);
    
        // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = {
            'reports': [],
            'number_of_weeks': numberOfWeeks
        };

        // Populate formData.reports based on checkbox states
        for (const [report, isChecked] of Object.entries(checkboxStates)) {
            if (isChecked) {
                formData.reports.push(report);
            }
        }  
    
        // Logic to populate formData.reports based on checkbox states

        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({
                'message': 'start',
                'data': formData
            }));

            console.log(formData)
        }
        
    }

    // Update number of weeks
    const handleNumberOfWeeksChange = (event) => {
        setNumberOfWeeks(event.target.value);
    };
    

    const handleCheckboxChange = (event) => {
        setCheckboxStates({
            ...checkboxStates,
            [event.target.id]: event.target.checked,
        });
    };

    return (
                
        <div className="container w-9/12 mx-auto my-10 py-10 px-20 bg-white rounded-lg">
            <div className="container mx-auto mt-4">
                
                <h1 className="text-3xl font-semibold mb-3">Update Dashboard and Reports</h1>
                
                <div className="bg-gray-50 border border-gray-50 text-blue-800 px-4 py-3 rounded relative text-center p-1" id="message">{message}</div>
            
                <div className="bg-gray-50 border border-gray-50 text-gray-700 px-4 py-3 rounded relative mt-2">
                    <div className="text-xs font-extralight text-gray-800 text-center p-1">
                        <span id="update">{update}</span> : <span id="progress-percentage">{progress}</span>%
                    </div>
                    <div className="progress-container bg-gray-300 rounded-full h-2.5 overflow-hidden">
                        <div id="progress-bar" className="progress-bar bg-indigo-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
                    </div>
                    
                    
                </div>

                <form id="reports-form" onSubmit={handleSubmit}>
                    <div className="space-y-4">

                        {cardData.map((item, index) => (
                            <StatusCard 
                                key={item.checkboxId}
                                checkboxId={item.checkboxId}
                                label={item.label}
                                isChecked={checkboxStates[item.isCheckedKey]}
                                setChecked={() => toggleCheckbox(item.isCheckedKey)}
                                onChange={handleCheckboxChange}

                                status={stageDone.length >= 5 && stageDone[item.statusIndex] ? 
                                            <span style={{ color: 'purple' }}>✅</span> : 
                                            checkboxStates[item.isCheckedKey] ? 
                                                <span style={{ color: 'black' }}>🕒</span> :
                                                <span className='text-xs italic font-light text-blue-400'>Skip</span>}
                            />
                        ))}

                        {/* Repeat the pattern for other checkboxes */}
                        {/* Card for Update Sales Dashboard */}
                        {/* Card for Create Post Sequence Report */}
                        {/* Card for Create LinkedIn Report */}
                        {/* Card for Create Intro Calls Weekly Report */}

                        {/* Card for Number of Weeks Input */}
                        <div className="card border p-4">
                            <div className="flex items-center">
                                <label className="text-sm font-light text-gray-800" htmlFor="number-of-weeks">
                                    Number of Weeks to Process:
                                </label>
                                <input 
                                    type="number" 
                                    id="number-of-weeks" 
                                    name="number_of_weeks" 
                                    min="1" 
                                    max="30" 
                                    step="1" 
                                    value={numberOfWeeks} 
                                    onChange={handleNumberOfWeeksChange}
                                    className="border-b border-gray-300 rounded text-center p-1 ml-2"/>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="text-center">
                            <button type="submit" className="bg-indigo-800 hover:bg-indigo-500 text-white font-normal py-2 px-2 rounded">
                                Click to start
                            </button>
                        </div>
                    </div>
                </form>

{/*                 
            <form id="reports-form" onSubmit={handleSubmit}>
            <table className='table-auto' style={{ width: '100%' }}>
                <tbody>
                <tr>
                    <td>
                        <input 
                            type="checkbox" 
                            id="pull-smartlead-data" 
                            checked={checkboxStates['pull-smartlead-data']} 
                            onChange={handleCheckboxChange}
                        />
                    </td>
                    <td>
                        <label className="text-sm font-light text-gray-800" htmlFor="pull-smartlead-data">Pull Smartlead Data</label>
                    </td>
                    <td>
                         
                    <div className="text-sm font-light text-gray-800">{stageDone.length >= 5 && stageDone[0] ? 
                                <span style={{ color: 'purple' }}>✅</span> : 
                                checkboxStates['pull-smartlead-data']? 
                                    <span style={{ color: 'black' }}>🕒</span> :
                                    <span style={{ color: 'black' }}>✖️</span>    
                                }
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <input 
                            type="checkbox" 
                            id="update-sales-dashboard" 
                            checked={checkboxStates['update-sales-dashboard']} 
                            onChange={handleCheckboxChange}
                        />
                    </td>
                    <td>
                        <label className="text-sm font-light text-gray-800" htmlFor="update-sales-dashboard">Update Sales Dashboard</label>
                    </td>
                    <td>
                         
                    <div className="text-sm font-light text-gray-800">{stageDone.length >= 5 && stageDone[1] ? 
                                <span style={{ color: 'purple' }}>✅</span> : 
                                checkboxStates['update-sales-dashboard']? 
                                    <span style={{ color: 'black' }}>🕒</span> :
                                    <span style={{ color: 'black' }}>✖️</span>    
                                }
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <input 
                            type="checkbox" 
                            id="create-post-sequence-report" 
                            checked={checkboxStates['create-post-sequence-report']} 
                            onChange={handleCheckboxChange}
                        />
                    </td>
                    <td>
                        <label className="text-sm font-light text-gray-800" htmlFor="create-post-sequence-report">Create Post Sequence Report
                        </label>
                    </td>
                    <td>
                         
                    <div className="text-sm font-light text-gray-800">{stageDone.length >= 5 && stageDone[2] ? 
                                <span style={{ color: 'purple' }}>✅</span> : 
                                checkboxStates['create-post-sequence-report']? 
                                    <span style={{ color: 'black' }}>🕒</span> :
                                    <span style={{ color: 'black' }}>✖️</span>    
                                }
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <input 
                            type="checkbox" 
                            id="create-linkedin-report"
                            checked={checkboxStates['create-linkedin-report']}  
                            onChange={handleCheckboxChange}
                        />
                    </td>
                    <td>
                        <label className="text-sm font-light text-gray-800" htmlFor="create-linkedin-report">Create LinkedIn Report</label>
                    </td>
                    <td>
                         
                    <div className="text-sm font-light text-gray-800">{stageDone.length >= 5 && stageDone[3] ? 
                                <span style={{ color: 'purple' }}>✅</span> : 
                                checkboxStates['create-linkedin-report']? 
                                    <span style={{ color: 'black' }}>🕒</span> :
                                    <span style={{ color: 'black' }}>✖️</span>    
                                }
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <input 
                            type="checkbox" 
                            id="create-intro-calls-weekly-report" 
                            checked={checkboxStates['create-intro-calls-weekly-report']}  
                            onChange={handleCheckboxChange}
                        />
                    </td>
                    <td>
                        <label className="text-sm font-light text-gray-800" htmlFor="create-intro-calls-weekly-report">Create Intro Calls Weekly Report</label>
                    </td>
                    <td>
                         
                        <div className="text-sm font-light text-gray-800">{stageDone.length >= 5 && stageDone[4] ? 
                                <span style={{ color: 'purple' }}>✅</span> : 
                                checkboxStates['create-intro-calls-weekly-report']? 
                                    <span style={{ color: 'black' }}>🕒</span> :
                                    <span style={{ color: 'black' }}>✖️</span>    
                                }
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <label className="text-sm font-light text-gray-800" htmlFor="number-of-weeks">Number of Weeks to Process:</label>
                    </td>
                    <td>
                        <input 
                            type="number" 
                            id="number-of-weeks" 
                            name="number_of_weeks" 
                            min="1" 
                            max="30" 
                            step="1" 
                            value={numberOfWeeks} 
                            onChange={handleNumberOfWeeksChange}
                            className="border-b border-gray-300 rounded text-center p-1"/>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <button type="submit" className="bg-indigo-800 hover:bg-indigo-500 text-white font-normal py-2 px-2 rounded">Click to start</button>
                    </td>
                </tr>
                </tbody>
                </table>
            </form> */}
        
            </div>
        </div>
    );
};

export default Dashboard;
