import React from "react";
import SearchCardLeft from './SearchCardLeft';
import SearchCardMid from './SearchCardMid';
import SearchCardRight from './SearchCardRight';


const FullCard = (props) =>{

    const {groupedResults} = props;

    return(
        <div>

            {Object.entries(groupedResults).map(([email, groupData]) => (
                <div key={email} className="card bg-white-100 text-blue-800 my-4 p-6 rounded border shadow">
                    <div className="flex justify-between items-center">
                        <div className='grid grid-cols-4 w-full'>
                            <div className="flex items-center col-span-1">
                                <SearchCardLeft groupData={groupData} />
                            </div>
                            <div className="flex items-center col-span-2">
                                <SearchCardMid groupData={groupData} email={email} />
                            </div>
                            <div className="flex items-center justify-center col-span-1">
                                <SearchCardRight groupData={groupData} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </div>
    );
};

export default FullCard;