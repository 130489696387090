import React, { useEffect, useState } from "react";
import '../../../styles/tables.css'


const TableView = (props) => {

    const { groupedResults } = props;
    const [selectedRows, setSelectedRows] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    // State to manage pop-up visibility and content
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState(null);

    // Function to open the pop-up with specific content
    const openPopup = (groupData) => {
        setPopupContent(groupData);
        setPopupOpen(true);
    };

    // Function to close the pop-up
    const closePopup = () => {
        setPopupOpen(false);
        setPopupContent(null);
    };

    // Pop-up component
    const Popup = ({ data }) => {
        if (!data) return null;

        const sortedSentDetails = [...data.sentDetails].sort((a, b) => new Date(b.time) - new Date(a.time));

        // Function to handle click on the overlay
        const handleOverlayClick = (e) => {
            // Close the pop-up only if the click is on the overlay itself, not its children
            if (e.target === e.currentTarget) {
                closePopup();
            }
        };

        const determineLineStyles = (sortedSentDetails) => {
            const lineStyles = [];
            let isPreviousSequenceOne = false;

            for (const detail of sortedSentDetails) {
                if (detail.sequence === "1") {
                    lineStyles.push("bg-dotted");
                    isPreviousSequenceOne = true;
                } else {
                    lineStyles.push(isPreviousSequenceOne ? "bg-dotted" : "bg-solid");
                    isPreviousSequenceOne = false;
                }
            }

            return lineStyles;
        };

        const styleChangePoints = determineLineStyles(sortedSentDetails);

        return (
            <div
                className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
                onClick={handleOverlayClick}  // Add click event to overlay
            >
                <div className="relative top-20 mx-auto p-5 border w-80 shadow-lg rounded-md bg-white">
                    <button onClick={closePopup} className="absolute top-0 right-0 mt-2 mr-2">
                        <svg className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                    <h2>{data.name}</h2>
                    <h3>{data.title}</h3>
                    <div>{data.company}</div>

                    <ul className="relative border-gray-200">
                    {sortedSentDetails.map((detail, index) => (
                        <li key={index} className="mb-2 ml-4 relative"> {/* Timeline item */}
                            {/* Apply conditional line segment styles */}
                            <div className={`absolute left-0 top-0 w-0.5 ${index === 0 || styleChangePoints.includes(index - 1) ? 'bg-dotted' : 'bg-solid'}`} style={{ top: `${index * 100}%`, height: '100%' }}></div>

                            <div className="flex items-center"> {/* Align dot and content */}
                                <div className="absolute w-3 h-3 bg-blue-500 rounded-full -left-1.5 border border-white z-10"></div> {/* Timeline dot */}
                                <div className="relative bg-white p-2 rounded-md shadow ml-4"> {/* Content card */}
                                    <div className="absolute h-3 w-3 bg-white rotate-45 -left-1.5 -ml-1"></div> {/* Pointer */}
                                    {detail.time} - Sequence: {detail.sequence}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>





            </div>
            </div >
        );
    };




const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
    }
    setSortConfig({ key, direction });
};

const sortedItems = React.useMemo(() => {
    let sortableItems = Object.entries(groupedResults);
    if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
            if (a[1][sortConfig.key] < b[1][sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[1][sortConfig.key] > b[1][sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }
    return sortableItems;
}, [groupedResults, sortConfig]);


useEffect(() => {
    if (selectAll) {
        const allSelected = Object.fromEntries(
            Object.entries(groupedResults).map(([email, data]) => [email, data])
        );
        setSelectedRows(allSelected);
    } else {
        setSelectedRows({});
    }
}, [selectAll, groupedResults]);

const handleCheckboxChange = (email, isChecked) => {
    setSelectedRows(prev => ({
        ...prev,
        [email]: isChecked ? groupedResults[email] : undefined
    }));
};

const downloadCSV = () => {
    const selectedData = Object.values(selectedRows).filter(Boolean);
    if (selectedData.length === 0) {
        alert("No rows selected");
        return;
    }

    let csvContent = "data:text/csv;charset=utf-8,";
    // Add CSV headers
    csvContent += "Lead Name,Company,Title,Website,LinkedIn,Employees,Funding,Last Contacted,Days Since Last Contact\n";

    // Add rows
    selectedData.forEach(row => {
        const lastContactedDate = getLastContactedDate(row.sentDetails);
        const daysSinceLastContact = getDaysSinceLastContact(row.sentDetails);
        const rowString = `"${row.name}",${row.company},"${row.title}",${row.website},${row.linkedin_url},${row.employees},${row.funding},${lastContactedDate},${daysSinceLastContact}\n`;
        csvContent += rowString;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "selected_rows.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
};



const TableHeaderCell = ({ children }) => (
    <th className="px-5 py-2 border-b-2 border-blue-800 bg-white text-left text-xs font-semibold text-indigo-800 tracking-wider">
        {children}
    </th>
);

const TableRowCell = ({ children }) => (
    <td className="px-5 py-4 border-b border-gray-100 text-xs">
        {children}
    </td>
);

const getLastContactedDate = (sentDetails) => {
    if (!sentDetails || sentDetails.length === 0) {
        return 'N/A';
    }

    let lastContactedTime = sentDetails
        .map(detail => detail.time)
        .sort((a, b) => new Date(b) - new Date(a))[0]
        .split('T')[0];

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(lastContactedTime);
    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year

    return `${dayOfWeek}, ${dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth}-${month}-${year}`;
};


const getDaysSinceLastContact = (sentDetails) => {
    if (sentDetails.length === 0) return 'N/A';

    let daysSinceLastContact = Math.floor(
        (new Date() - new Date(
            sentDetails
                .map(detail => new Date(detail.time))
                .sort((a, b) => b - a)[0]
        )) / (1000 * 60 * 60 * 24) // Calculate the difference in days
    );

    return daysSinceLastContact;
};



return (
    <div className="mt-2 p-2 border rounded">
        {/* ... existing search bar code ... */}

        {/* Table for Displaying Results */}
        <div className="relative">
            <button onClick={downloadCSV} className="flex mt-4 py-1 px-2 border font-medium bg-white-500 text-gray-700 text-xs hover:text-indigo-800 hover:border-indigo-300 transition-all rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="relative  hover:text-indigo-800 justify-center mr-1 w-3 h-3">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                Export

            </button>
        </div>

        <table className="min-w-full leading-normal mt-1">
            <thead>
                <tr>
                    <TableHeaderCell>
                        <input
                            type="checkbox"
                            className="rounded"
                            checked={selectAll}
                            onChange={(e) => setSelectAll(e.target.checked)}
                        />
                    </TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('company')}>Company</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('employees')}>Employees</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('funding')}>Funding</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('lead_name')}>Lead Name</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('title')}>Title</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('email')}>Email</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('tag')}>Tag</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('last_contacted')}>Last Contacted</TableHeaderCell>
                    <TableHeaderCell onClick={() => requestSort('days_since_last_contact')}>Days Since Last Contact</TableHeaderCell>
                    <TableHeaderCell>Source</TableHeaderCell>
                    {/* ... other headers ... */}
                </tr>

            </thead>
            <tbody>
                {sortedItems.map(([email, groupData]) => (

                    <tr key={email} className="bg-white hover:bg-gray-50 transition-colors">
                        <TableRowCell>
                            <input
                                type="checkbox"
                                className="rounded"
                                checked={!!selectedRows[email]}
                                onChange={(e) => handleCheckboxChange(email, e.target.checked)}
                            />
                        </TableRowCell>
                        {/* <TableRowCell><a href={groupData.website} target="_blank" rel="noopener noreferrer">{groupData.company}</a></TableRowCell> */}
                        <TableRowCell>
                            <div className="group flex items-center font-bold text-blue-600 hover:text-blue-950 transition-all relative">
                                <span className='mr-2'>
                                    <a href="#">{groupData.company}</a>
                                </span>
                                <div className="flex relative transition-all right-0">
                                    <a href={groupData.website} target="_blank" rel="noopener noreferrer" className="mx-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 stroke-black hover:stroke-blue-500 transition-colors">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                        </svg>
                                    </a>
                                    <a href={groupData.crunchbase} target="_blank" rel="noopener noreferrer" className="mx-1" >
                                        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth={0} stroke="currentColor" className="w-3 h-3 hover:fill-blue-500 transition-colors">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.6 0H2.4A2.41 2.41 0 0 0 0 2.4v19.2A2.41 2.41 0 0 0 2.4 24h19.2a2.41 2.41 0 0 0 2.4-2.4V2.4A2.41 2.41 0 0 0 21.6 0zM7.045 14.465A2.11 2.11 0 0 0 9.84 13.42h1.66a3.69 3.69 0 1 1 0-1.75H9.84a2.11 2.11 0 1 0-2.795 2.795zm11.345.845a3.55 3.55 0 0 1-1.06.63 3.68 3.68 0 0 1-3.39-.38v.38h-1.51V5.37h1.5v4.11a3.74 3.74 0 0 1 1.8-.63H16a3.67 3.67 0 0 1 2.39 6.46zm-.223-2.766a2.104 2.104 0 1 1-4.207 0 2.104 2.104 0 0 1 4.207 0z" />
                                        </svg>
                                    </a>
                                    <a href={groupData.company_linkedin_url} target="_blank" rel="noopener noreferrer" className="mx-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth={0} stroke="currentColor" className="w-3 h-3 hover:fill-blue-500 transition-colors">
                                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" /></svg>
                                    </a>
                                </div>
                            </div>
                        </TableRowCell>
                        <TableRowCell>{groupData.employees}</TableRowCell>
                        <TableRowCell>$ {groupData.funding}</TableRowCell>
                        <TableRowCell><a href={groupData.linkedin_url} target="_blank" rel="noopener noreferrer">{groupData.name}</a></TableRowCell>
                        <TableRowCell>{groupData.title}</TableRowCell>
                        <TableRowCell>
                            <span onClick={() => openPopup(groupData)} style={{ cursor: 'pointer' }}>
                                {email}
                            </span>
                        </TableRowCell>
                        <TableRowCell>{groupData.lead_category}</TableRowCell>
                        <TableRowCell>{getLastContactedDate(groupData.sentDetails)}</TableRowCell>
                        <TableRowCell>{getDaysSinceLastContact(groupData.sentDetails)}</TableRowCell>
                        <TableRowCell>{groupData.contact_source}</TableRowCell>
                        {/* ... other data cells ... */}
                    </tr>

                ))}
            </tbody>
            {isPopupOpen && <Popup data={popupContent} />}
        </table>
    </div>
);
};

export default TableView;
