// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes stretch {
    0%, 100% { transform: scaleX(1); }
    50% { transform: scaleX(1.01); } /* Stretch the bar to 120% of its width */
}

.progress-container {
    /* Container styles */
    width: 100%; /* Set a fixed width for the container */
}

.progress-bar {
    /* Basic progress bar styles */
    background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, .15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, .15) 50%,
        rgba(255, 255, 255, .15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 50px 50px;
    width: 0%; /* Initial width */
    animation: stretch 2s ease-in-out infinite, move-stripes 2s linear infinite;
    transform-origin: left center; /* Ensure stretching happens from the left */
}

/* Update the width of the progress bar as needed */
/* This can be dynamically updated via JavaScript */
#progress-bar {
    width: 50%; /* Example: progress at 50% */
}

.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%; /* or a specific height as needed */
  }
.logout-container {
    margin-top: auto; /* Pushes everything inside it to the bottom */
    width: 100%; /* Ensures it takes up the full width of the sidebar */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,WAAW,oBAAoB,EAAE;IACjC,MAAM,uBAAuB,EAAE,EAAE,yCAAyC;AAC9E;;AAEA;IACI,qBAAqB;IACrB,WAAW,EAAE,wCAAwC;AACzD;;AAEA;IACI,8BAA8B;IAC9B;;;;;;;;;KASC;IACD,0BAA0B;IAC1B,SAAS,EAAE,kBAAkB;IAC7B,2EAA2E;IAC3E,6BAA6B,EAAE,4CAA4C;AAC/E;;AAEA,mDAAmD;AACnD,mDAAmD;AACnD;IACI,UAAU,EAAE,6BAA6B;AAC7C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY,EAAE,mCAAmC;EACnD;AACF;IACI,gBAAgB,EAAE,8CAA8C;IAChE,WAAW,EAAE,sDAAsD;EACrE","sourcesContent":["@keyframes stretch {\n    0%, 100% { transform: scaleX(1); }\n    50% { transform: scaleX(1.01); } /* Stretch the bar to 120% of its width */\n}\n\n.progress-container {\n    /* Container styles */\n    width: 100%; /* Set a fixed width for the container */\n}\n\n.progress-bar {\n    /* Basic progress bar styles */\n    background-image: linear-gradient(\n        135deg,\n        rgba(255, 255, 255, .15) 25%,\n        transparent 25%,\n        transparent 50%,\n        rgba(255, 255, 255, .15) 50%,\n        rgba(255, 255, 255, .15) 75%,\n        transparent 75%,\n        transparent\n    );\n    background-size: 50px 50px;\n    width: 0%; /* Initial width */\n    animation: stretch 2s ease-in-out infinite, move-stripes 2s linear infinite;\n    transform-origin: left center; /* Ensure stretching happens from the left */\n}\n\n/* Update the width of the progress bar as needed */\n/* This can be dynamically updated via JavaScript */\n#progress-bar {\n    width: 50%; /* Example: progress at 50% */\n}\n\n.sidebar {\n    display: flex;\n    flex-direction: column;\n    height: 100%; /* or a specific height as needed */\n  }\n.logout-container {\n    margin-top: auto; /* Pushes everything inside it to the bottom */\n    width: 100%; /* Ensures it takes up the full width of the sidebar */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
