import React from "react";

const StatusCard = ({ checkboxId, label, isChecked, setChecked, status }) => {
    // Function to handle card click
    const handleCardClick = () => {
        // Toggle the checked state
        setChecked(!isChecked);
    };

    return (
        <div 
            className={`card rounded p-4 flex cursor-pointer  ${isChecked ? 'shadow-sm border-2 border-indigo-200 transition-all' : 'transition-all border'}`}
            onClick={handleCardClick}
        >
            {/* Hidden Checkbox */}
            <input 
                type="checkbox" 
                id={checkboxId} 
                checked={isChecked} 
                onChange={() => {}} // Empty function since the actual change is handled by card click
                className="absolute opacity-0"
            />
            <div className="flex-grow">
                <label className="text-base font-bold text-indigo-800" htmlFor={checkboxId}>
                    {label}
                </label>
                <div className="text-sm font-light text-gray-800">
                    {status}
                </div>
            </div>
        </div>
    );
};



export default StatusCard;