import React from "react";

const SearchCardMid = ({ email, groupData }) => {

    // console.log("groupData", groupData); // Log the whole groupData
    const filteredReplyTimes = groupData.replyTimes.filter(replyTime => replyTime !== 'None' && replyTime !== '');
    // console.log("Filtered replyTimes", filteredReplyTimes); // Log the filtered replyTimes

    const getColorClass = (category) => {
        switch (category) {
            case 'Meeting Request':
                return "bg-green-100 text-green-800";
            case 'Out of office':
                return "bg-yellow-100 text-yellow-800";
            case 'Not Interested':
                return "bg-gray-100 text-gray-800";
            default:
                return "bg-blue-100 text-blue-800";
        }
    };

    const chipClass = getColorClass(groupData.lead_category);

    const getLastContactedDate = (sentDetails) => {
        if (sentDetails.length === 0) return 'N/A';
    
        // Extract dates and sort them to find the most recent
        let lastContactedDate = sentDetails
            .map(detail => new Date(detail.time))
            .sort((a, b) => b - a)[0]
            .toISOString().split('T')[0];
    
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
        const date = new Date(lastContactedDate);
        const dayOfWeek = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear().toString().substr(-2); // Get last two digits of year
    
        return `${dayOfWeek}, ${dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth}-${month}-${year}`;
    };
    

    const getDaysSinceLastContact = (sentDetails) => {
        if (sentDetails.length === 0) return 'N/A';
    
        let daysSinceLastContact = Math.floor(
            (new Date() - new Date(
                sentDetails
                    .map(detail => new Date(detail.time))
                    .sort((a, b) => b - a)[0]
            )) / (1000 * 60 * 60 * 24) // Calculate the difference in days
        );
    
        return daysSinceLastContact;
    };
    

    const lastContacted = getLastContactedDate(groupData.sentDetails);
    const daysSinceLastContact = getDaysSinceLastContact(groupData.sentDetails)


    return (
        <div>

            <div className="m-0 text-lg font-bold text-blue-900">
                <a href={`${groupData.linkedin_url}`} target="_blank" rel="noopener noreferrer">
                    {groupData.name}
                </a>
            </div>
            <div className="m-0 text-sm font-light text-blue-900">{groupData.title}</div>
            <div className="mb-1 text-xs text-gray-600">
                Times Replied: {groupData.replyTimes.filter(time => time !== 'None').length}
            </div>
            <div>
                {filteredReplyTimes.length > 0 && (
                    <span className={`${chipClass} text-xs px-3 py-1 rounded-full`} key={email}>
                        {console.log("lead_category", groupData.lead_category)}
                        {groupData.lead_category}
                        {/* You can add more details here */}
                    </span>
                )}
            </div>
            <div className="mb-1 text-xs text-gray-600">
                Last Sent: 
                {lastContacted}
                <br />
                Days Since Last Contacted: 
                {daysSinceLastContact}
            </div>
            <div className='text-xs mt-4 text-gray-400'>{email}</div>

        </div>
    )
}

export default SearchCardMid;