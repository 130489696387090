import Papa from 'papaparse'

const SearchCardRight = ({groupData}) => {

    const downloadCSV = (groupedData) => {
        if (groupedData.items && groupedData.items.length > 0) {
            const csv = Papa.unparse(groupedData.items);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `results_${groupedData.items[0].lead_email}.csv`;
            link.click();
        } else {
            console.error("No data to download.");
        }
    };

    return(
        <div>
            <button
                className="bg-white-500 p-1 rounded text-xs"
                onClick={() => downloadCSV(groupData)}
                aria-label="Download Group"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="grey" viewBox="0 0 24 24" strokeWidth={1.5} stroke="gray" className="w-3 h-3">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
            
            </button>
        </div>
    )
}

export default SearchCardRight;