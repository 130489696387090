import React from 'react';
import { BrowserRouter as Router, Route, Routes,Link } from 'react-router-dom';
import './styles/tailwind.css'
import './styles/Dashboard.css'
import {WebSocketProvider} from './components/WebSocketProvider';
import Dashboard from './components/Dashboard';
import Reports from './components/Reports';
import Sidebar from './components/Sidebar';
import Search from './components/Search';
import FileUploader from './components/Checks/Checks';
import FileUploaderWeekly from './components/WeeklyDataChecks/WeeklyChecks';
import Login from './components/Login'; // Make sure you have this component created
import ProtectedRoute from './context/ProtectedRoute'; // And this one
import { AuthProvider } from './context/AuthContext';




const App = () => {
  const isLoggedIn = !!localStorage.getItem('token'); // Check if the token exists to determine login state

  return (
      <AuthProvider>
        <WebSocketProvider>
          <Router>
            <div className="flex h-screen">
              {isLoggedIn && <Sidebar />}
              <div className={`flex-1 overflow-auto ${!isLoggedIn ? 'flex' : ''}`}>
              <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  } />
                  <Route path="/reports" element={
                    <ProtectedRoute>
                      <Reports />
                    </ProtectedRoute>
                  } />
                  <Route path="/search" element={
                    <ProtectedRoute>
                      <Search />
                    </ProtectedRoute>
                  } />
                  <Route path="/checks" element={
                    <ProtectedRoute>
                      <FileUploader />
                    </ProtectedRoute>
                  } />
                  <Route path="/weekly_checks" element={
                    <ProtectedRoute>
                      <FileUploaderWeekly />
                    </ProtectedRoute>
                  } />
                </Routes>
              </div>
            </div>
          </Router>
        </WebSocketProvider>
      </AuthProvider>
  );
};

export default App;
