import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

function FileUploader() {
    const [file, setFile] = useState(null);
    const [isFileReady, setIsFileReady] = useState(false);
    const downloadUrl = 'http://127.0.0.1:8001/checks/download/'; // URL to download the processed file
    const [isProcessing, setIsProcessing] = useState(false);

    // useEffect(() => {
    //     axios.get('http://127.0.0.1:8001/checks/testget/',{ withCredentials: true })
    //         .then(response => {
    //             console.log('testget csrf cookie set')
    //         })
    //         .catch(error => {
    //             console.log(`CSRF cookie not set as ${error}`)
    //         });
    // }, []);
    const onDrop = useCallback(acceptedFiles => {
        // Assuming only single file upload
        const uploadedFile = acceptedFiles[0];
        console.log('Dropped file:', uploadedFile)
        setFile(uploadedFile);
        handleFileUpload(uploadedFile);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async (fileToUpload) => {
        setIsProcessing(true);
        const formData = new FormData();
        formData.append('file', fileToUpload);

        console.log('Uploading file:', file);

        // Get CSRF token from the cookie
        const csrfToken = document.cookie.split(';')
            .find(c => c.trim().startsWith('csrftoken='))
            ?.split('=')[1];
        try {
            // POST to your Django upload endpoint
            await axios.post('http://127.0.0.1:8001/checks/upload/', formData, {
                // headers: {
                //     'X-CSRFToken': csrfToken,
                // },
                // withCredentials: true,
            });

            // Trigger processing
            await axios.post('http://127.0.0.1:8001/checks/process/');

            setIsFileReady(true)
        } catch (error) {
            console.error('Error uploading file:', error.response || error)
        } finally {
            setIsProcessing(false)
        }
        // Provide download link (or automatically download)
    };


    return (
        <div className='container m-16 h-96 mx-auto'>
            <div className='container m-16 h-96 mx-auto'>

            <div>
                {isFileReady ? (
                    // If the file is ready, show the download button
                    <button className='flex flex-col justify-center items-center rounded-full place-content-center mx-4 px-4 py-2 bg-indigo-500 text-white' onClick={() => window.location.href = downloadUrl}>
                        Download Processed File
                    </button>
                ) : (
                    // Otherwise, show the dropzone
                    <div {...getRootProps()} className="dropzone flex flex-col justify-center items-center border-dashed border-2 text-indigo-500 border-indigo-200 h-96 mx-auto hover:border-indigo-700 hover:text-indigo-700">
                        <input {...getInputProps()} />

                        <p >Drag 'n' drop some files here, or click to select files</p>
                        
                        {isProcessing && <p>Processing...</p>}
                    </div>
                )}
            </div>
            </div>


            {/* <input type="file" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload and Process</button>

            {isFileReady && (
                <button onClick={() => window.location.href = downloadUrl}>
                    Download Processed File
                </button>
            )} */}
            
        </div>

    );
}

export default FileUploader;


